<template>
  <footer>
    <div class="footer-content inner-content">
      <div class="footer-item">
        <p class="footer-subtitle">Visiting address</p>
        <p>Universitetsvägen 10 F</p>
      </div>
      <div class="footer-item">
        <p class="footer-subtitle">Postal address</p>
        <p>SOFI, Stockholms universitet</p>
        <p>106 91 Stockholm</p>
      </div>
      <div class="footer-item">
        <p class="footer-subtitle">Contact</p>
        <a href="info@integrateyouth.org" target="blank"
          >info@integrateyouth.org</a
        >
        <a href="tel:08-16 20 00">
          <p>
            08-16 20 00
          </p>
        </a>
      </div>
      <div class="footer-item">
        <p class="footer-subtitle">Sitemap</p>
        <router-link :to="{ name: 'About' }">
          <p>About</p>
        </router-link>
        <router-link :to="{ name: 'Team' }"><p>Team</p></router-link>
        <router-link :to="{ name: 'Research output' }"
          ><p>Research output</p></router-link
        >
        <router-link :to="{ name: 'Fact sheets' }"
          ><p>Fact sheets</p></router-link
        >
        <router-link :to="{ name: 'News and more' }"
          ><p>News and more</p></router-link
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "BaseFooter"
};
</script>

<style lang="scss" scoped>
@import "@/styles/style.scss";
footer {
  background-color: $extra-light-gray;
  font-size: 0.8rem;
  .footer-content {
    display: grid;
    grid-template-columns: max-content;
    grid-gap: 1.5rem;
    align-items: flex-start;
    color: $dark-gray;
    @media (min-width: $phone) {
      grid-gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: $tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    .footer-item {
      height: 100%;
      padding-right: 2rem;
    }
  }
  .footer-subtitle {
    font-weight: bold;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $gray;
  }
  p {
    margin: 0.5rem 0;
    font-size: 0.8rem;
  }
}
</style>
